import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/layout';

import * as styles from 'styles/shared.module.scss';
import RoomArticle from 'components/RoomArticle';
import { graphql, useStaticQuery } from 'gatsby';

export const query = graphql`
  {
    allStrapiRooms {
      nodes {
        id
        title
        persons
        beds
        baths
        images {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

function RoomsPage() {
  const initialRef = React.useRef();
  const allData = useStaticQuery(query);
  const rooms = allData.allStrapiRooms.nodes;

  return (
    <Flex justify="center" align="center" direction="column">
      <Text className={`${styles.roomDescriptionContainer} ${styles.descriptionWithImageWrapper}`}>
        Pensiunea ”Codruța” vă pune la dispoziție 4 camere duble, sufragerie, sală de servit masa, un spațiu de recreere lângă șemineu, bucătărie, foișor cu grătar, parcare pentru mașini, apă caldă permanent, televiziune prin cablu, internet, aer condiționat.
      </Text>
      {rooms.map((room, index) => (
        <Box
          className={
            index % 2 === 0
              ? styles.grayPresentationContainer
              : styles.whitePresentationContainer
          }
        >
          <Box className={styles.descriptionWithImageWrapper}>
            <RoomArticle room={room} initialRef={initialRef} />
          </Box>
        </Box>
      ))}
    </Flex>
  );
}

export default RoomsPage;

import React from 'react';
import {
  Box, Flex, Text, WrapItem,
} from '@chakra-ui/layout';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as styles from './Facility.module.scss';

function Facility({ icon, name }) {
  return (
    <Box className={styles.facilityContainer}>
      {/* <WrapItem> */}
      <Flex justify="center" align="center" direction="row">
        <FontAwesomeIcon icon={icon} />
        <Text className={styles.facilityName}>{name}</Text>
      </Flex>
      {/* </WrapItem> */}
    </Box>
  );
}

export default Facility;

import React, { useState } from 'react';

import {
  Box, Flex, Heading, Text,
} from '@chakra-ui/layout';
import {
  Table, Tbody, Tr, Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import {
  faCar, faCoffee, faHamburger, faSnowflake, faThermometerFull, faTv, faUtensils, faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Pagination, Scrollbar, A11y, EffectFade,
} from 'swiper';

import * as styles from './RoomArticle.module.scss';
import Facility from './Facility';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/effect-fade';

const PhotoSwiper = ({
  images, currentIndex, handleCurrentImage, handleModal,
}) => (
  <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
    effect="fade"
    spaceBetween={50}
    slidesPerView={1}
    navigation
    pagination={{ clickable: true }}
    onSlideChange={(swiper) => handleCurrentImage(swiper.activeIndex)}
    initialSlide={currentIndex}
    onClick={() => {
      handleModal();
    }}
  >
    {
      images.map((image) => {
        const gatsbyImg = getImage(image.localFile);

        return (
          <SwiperSlide>
            <GatsbyImage className={styles.roomImage} image={gatsbyImg} />
          </SwiperSlide>
        );
      })
    }
  </Swiper>
);

function RoomArticle({ room, initialRef }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const {
    id, title, persons, beds, baths, images,
  } = room;

  const handleCurrentImage = (index) => {
    setCurrentImage(index);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isCentered
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent style={{ borderRadius: 25 }}>
          <ModalBody style={{ padding: 0, margin: 0, borderRadius: 25 }}>
            <PhotoSwiper
              images={images}
              currentIndex={currentImage}
              handleCurrentImage={handleCurrentImage}
              handleModal={handleCloseModal}
            />

          </ModalBody>
        </ModalContent>
      </Modal>
      <Box className={styles.roomContainer}>
        <Flex justify="center" align="flex-start" direction="column" className={styles.roomInfoContainer}>
          <Heading size="lg" className={styles.roomTitle}>{title}</Heading>

          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
            className={styles.roomAttributes}
          >
            {`${persons} ${persons === 1 ? 'persoana' : 'persoane'} `}
            &bull;
            {` ${beds} ${beds === 1 ? 'pat' : 'paturi'} `}
          </Box>

          <Flex justify="center" align="center" direction="row" className={styles.pricePerNightContainer} />
          <Table variant="simple" className={styles.tableContainer}>
            <Tbody>
              <Tr>
                <Td>
                  <Facility icon={faWifi} name="Wi-fi" />
                </Td>
                <Td>
                  <Facility icon={faSnowflake} name="Aer conditionat" />
                </Td>
              </Tr>

              <Tr>
                <Td>
                  <Facility icon={faTv} name="TV" />
                </Td>
                <Td>
                  <Facility icon={faCar} name="Parcare gratuita" />
                </Td>
              </Tr>

              <Tr>
                <Td>
                  <Facility icon={faUtensils} name="Acces la bucatarie" />
                </Td>
                <Td>
                  <Facility icon={faThermometerFull} name="Incalzire" />
                </Td>
              </Tr>

              <Tr>
                <Td>
                  <Facility icon={faCoffee} name="Aparat de cafea" />
                </Td>
                <Td>
                  <Facility icon={faHamburger} name="Gratar" />
                </Td>
              </Tr>
            </Tbody>
          </Table>
          {/* <Text className={styles.roomDescription}>{description}</Text> */}
        </Flex>

        <PhotoSwiper
          images={images}
          currentIndex={currentImage}
          handleCurrentImage={handleCurrentImage}
          handleModal={handleOpenModal}
        />
      </Box>
    </>
  );
}

export default RoomArticle;
